import React, { useEffect, } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import WorklogDetailsForUnit from "../../components/WorklogDetailsForUnit";
import { studentData } from "../../store/action/student.action";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";

const StudentList = (props) => {
  const invalidId = useSelector(state => state.student.invalidId);
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  useEffect(() =>{
    dispatch(studentData.request(id));
    if(id===""){
      alert("Invalid Link")
    }
  },[dispatch,id])
  if(id && invalidId === true){
    alert("Invalid Link")
  }
  return (
    <div>
      <div className={styles.mainContainer}>
        <Container
          className={`pt-3 ${styles.innerContainer}`}
          style={{ minHeight: "100vh" }}
        >
          <WorklogDetailsForUnit />
        </Container>
      </div>
    </div>
  );
};

StudentList.propTypes = {};

export default StudentList;
