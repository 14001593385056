import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";

import "./styles/global.scss"
import AppRoutes from "./pages/App/index";

function App({ history, context }) {


  // const initialLoadUserDone = useSelector(selectInitialLoadUserDone);
  // const userDetails = useSelector(selectBasicUser);
  // if(!initialLoadUserDone) return 'Loading...'
  return (
    <BrowserRouter>
        <AppRoutes/>
      </BrowserRouter>
  );
}

export default App;
