import React from "react";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import pencil from "../../../assets/images/pen.png"
import * as SiteActions from "../../../store/action/student.action";
import { useDispatch } from "react-redux";

const WorklogDetailsForUnitCard = (props) => {


const { data } = props;
const history = useHistory();
const dispatch = useDispatch();
// go to the sign page
const handleCardClick = () =>{
  var payload = {
    signSelected: true,
    signUnit: data
  }
  dispatch(SiteActions.showSign(payload));
  history.push("/sign");
}

  return (
    <>
    <div className={`${styles.studentBox}`} onClick={handleCardClick}>
    <div className={`${styles.studentDetails}`}>
      <ul>
      <li className={`${styles.details}`}>{data.StudentName}</li>
      <li className={`${styles.submitted}`}><span>{data.UnitName}</span></li>
      <li className={`${styles.dateinfo}`}><span className={`${styles.draft}`}>{data.UnitText}</span></li>
      </ul>
    </div>
    <div className={`${styles.pencilButton}`}>
      <button><img src={pencil} alt="pencil" /></button>
    </div>
    </div>
    </>
  );
        }


WorklogDetailsForUnitCard.propTypes = {};

export default WorklogDetailsForUnitCard;
