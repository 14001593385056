import React from "react";
import WorklogDetailsForUnitMobile from "./WorklogDetailsForUnitMobile/index";
import {  useSelector } from "react-redux";

const WorklogDetailsForUnit = (props) => {
  const studentData = useSelector(state => state.student.students.StudentUnits);
  //decide the resolution according to the screen needed, false initially for web based preference

  return (
    <div>
      <WorklogDetailsForUnitMobile newData={studentData}/>
    </div>
  );
};

export default WorklogDetailsForUnit;
