import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import watchAll from './sagas'



export const history = createBrowserHistory()


export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [routerMiddleware(history), sagaMiddleware]
  const store = createStore(
    createRootReducer(history),
    composeWithDevTools(
      applyMiddleware(...middlewares)
    )
  );
  sagaMiddleware.run(watchAll)

  return store
}
 