import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider, ReactReduxContext  } from 'react-redux';
import configureStore, { history } from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const store = configureStore()


ReactDOM.render(
  <React.StrictMode>
    
      <Provider store={store} context={ReactReduxContext} > 
        <App  history={history}/>
      </Provider> 
    
  </React.StrictMode> ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
