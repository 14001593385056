import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import studentReducer from "./student.reducer";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    student: studentReducer
  })

export default createRootReducer;

