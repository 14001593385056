import React from "react";
import SingaturePopup from "../../SignaturePopup";
import { useSelector } from "react-redux";

const Sign = () =>{
    const signatureData = useSelector(state => state.student.signUnit);
    const employerName = useSelector(state => state.student.students.EmployerFirstName);
    const employerCompany = useSelector(state => state.student.students.TradingName);
    return (
        <SingaturePopup unit = {signatureData} EmployerFirstName = {employerName} TradingName = {employerCompany}/>
    )
}

export default Sign;