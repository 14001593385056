import { createRequestTypes, action } from "../../utils/redux";

export const STUDENT_DATA = createRequestTypes("STUDENT_DATA");

export const studentData = {
  request: (data) => action(STUDENT_DATA.REQUEST,data),
  success: (data) => action(STUDENT_DATA.SUCCESS, data),
  failure: (error) => action(STUDENT_DATA.FAILURE, error),
};

export const STUDENT_SIGN = createRequestTypes("STUDENT_SIGN");

export const studentSign = {
  request: (data) => action(STUDENT_SIGN.REQUEST,data),
  success: (data) => action(STUDENT_SIGN.SUCCESS, data),
  failure: (error) => action(STUDENT_SIGN.FAILURE, error),
};

export const SHOW_SIGN = "SHOW_SIGN";

export const showSign = (data) => action(SHOW_SIGN, data);