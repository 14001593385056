import { call, put, takeLatest, all } from "@redux-saga/core/effects";
import { StudentApi } from "../../service/student.service";
import * as StudentActions from "../action/student.action";

function *getStudentsData(action){
    try{
        const {data} = yield call(StudentApi.getStudents, action.payload)
        //extract the data here
        
        yield put(StudentActions.studentData.success(data));

    } catch (e) {
        yield put(StudentActions.studentData.failure(e));
    }
}

function *signStudent(action){
    try{
        const {data} = yield call(StudentApi.sign, action.payload.image, action.payload.id)
        //extract the data here
        
        yield put(StudentActions.studentSign.success(data));

    } catch (e) {
        yield put(StudentActions.studentSign.failure(e));
    }
}

function *studentsSaga(){
    yield all([
        takeLatest(StudentActions.STUDENT_DATA.REQUEST, getStudentsData),
        takeLatest(StudentActions.STUDENT_SIGN.REQUEST, signStudent)
    ]);
}

export default studentsSaga;