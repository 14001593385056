import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import backicon from "./assets/images/backicon.png";
import styles from "./styles/global.module.scss";

import * as SiteActions from "./store/action/student.action"
import { useDispatch, useSelector } from "react-redux";

const SingaturePopup = (props) => {

  var signSelected = useSelector((state) => state.student.signSelected);
  var signSuccess = useSelector((state) => state.student.signSuccess);
  var loadingSign = useSelector((state) => state.student.loadingSign);

  const history = useHistory()
  useEffect(()=>{
    if(!signSelected && !loadingSign && signSuccess){
      setTimeout(function () {
        history.goBack();
      }, 2000);
    }

  },[signSelected,signSuccess,loadingSign,history])

  const [signPadRef, setSignPadRef] = useState({});
  const [values, setValues] = useState({
    show: "block",
    text: "text",
    dataURI: null,
    signPadRef: "",
    didRedirect: false,
  });
  const signature = props.unit;
  const dispatch = useDispatch();

    // To Clear Canvas
  const clearCanvas = () => {
    setSignPadRef(signPadRef.clear());
    setEmpty(true)
  };

  //if the signature box is empty submitbutton is disable
  const [empty, setEmpty] = useState(true);
  const signEnable=()=>{
    setEmpty(false)
  }
  
  // to trim out signatures
  const trimSignatures = () => {
    setValues({
      ...values,
      dataURI: signPadRef.getTrimmedCanvas().toDataURL("image/png"),
    });
    var payload = {
      image:signPadRef.getTrimmedCanvas().toDataURL("image/png"),
      id:signature.SignId
    }
    dispatch(SiteActions.studentSign.request(payload));
  };
  const setRef = (reference) => {
    setSignPadRef(reference);
  };

  const signaturePopupComponent = () => {
    return (
      <div
        className="modal show"
        style={{ display: values.show }}
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className={`${styles.signHeader}`}>
              <button onClick={()=> history.goBack()}>
              <img src={backicon} alt="back" /></button>
              <h5>{signature.StudentName}</h5></div>
              <p className="pt-0 mt-0">{signature.UnitName}</p>

              <p>{signature.UnitText}</p>

              <small >I {props.EmployerFirstName} of {props.TradingName} support that {signature.StudentName} is competent in the workplace for this unit of competency to industry and company standards. I agree for this signature to be placed on the training plan for {signature.StudentName} for this unit </small>
            </div>

            <div className="modal-body text-center">
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: "signCanvas" }}
                ref={setRef}
                onBegin={signEnable}
              />
            </div>
            <div className="col-md pb-4 mx-auto row">
              <div className="center-width">
              <div className="col-md-6 centerBtn" >
              <button
                  className="btn btn-active halfBtn btn-info"
                  onClick={trimSignatures}
                  disabled={empty}
                >
                  Submit
                </button>
              </div>

              <div className=" col-md-6 centerBtn">
                <button
                  className="btn btn-danger halfBtn bg-danger"
                  onClick={clearCanvas}                 
                >
                  Clear
                </button>
              </div>
              </div>
            </div>
            <div className="modal-body">
              {signSelected?<></>:signSuccess?"Signed successfully":"Signing failed please try again"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {signaturePopupComponent()}
    </div>
  );
};

export default SingaturePopup;
