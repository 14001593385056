import React from "react";

import { Route, Switch } from "react-router-dom";
import Sign from "../Sign";
//import EmployerVerifyLink from '../Components/EmployerVerifyLink';
// Students Pages
import StudentList from "../StudentList";

export default function AppRoutes(props) {
  return (
    <>
      <Switch>
        {/* Public Routes */}
        <Route exact path="/" component={StudentList} />
        <Route exact path="/sign" component={Sign} />
      </Switch>
    </>
  );
}
