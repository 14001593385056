import React from "react";
import WorklogDetailsForUnitCard from "../WorklogDetailsForUnitCard/index";
import styles from "./styles.module.scss";
import logo from "../../../assets/images/logo.jpg";
import loadingImg from "../../../assets/images/loading.gif";
import { Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";

const WorklogDetailsForUnitMobile = (props) => {
  const { newData } = props;
  const empFirstName = useSelector(state => state.student.students.EmployerFirstName);
  const loadingStudents = useSelector(state => state.student.loadingStudents);
  return (
    <div className="Worklog-container">
      <Navbar bg="white" className={`${styles.nav} `}>
            <div className={`${styles.activity}`}> Hi {empFirstName}</div> 
        <img src={logo} className={`${styles.logoImg}`} alt="logoImage" />
      </Navbar>
      <div
        className="subarea"
        style={{
          marginLeft: "-12px",
          marginRight: "-12px",
          backgroundColor: "#F3F3F3",
          borderTop: "1px solid #E1E1E1",
          borderBottom: "1px solid #E1E1E1",

          fontWeight: "500",
        }}
      >
        <div className={`${styles.subheading}`}>Pending Signatures</div>
      </div>
      {/* <h2>{error}</h2> */}
      {loadingStudents ? 
        <img src={loadingImg} alt="loaderImage" className={`${styles.loaderImage}`}/>:<></>
      }
      {newData && !loadingStudents ? newData.map((elem) => (
        <WorklogDetailsForUnitCard data={elem} />
      )) : <></>}
      {
        newData && newData.length === 0 && !loadingStudents ? <p>
          Well done,<br/>
          <br/>
You've signed off everything you can at the moment<br/>
<br/>
We will let you know when the next group of units are ready.<br/>
<br/>
In the mean time if there is anything else we can do for you or that you would like to know about your apprentice please contact us<br/>
<br/>
Email hello@cti.edu.au<br/>
Phone 1 300 859 091
        </p>:<></>
      }
    </div>
  );
};

WorklogDetailsForUnitMobile.propTypes = {};

export default WorklogDetailsForUnitMobile;
